import { ethers } from "ethers";
import { Config } from "@wagmi/core";
import { LedgerQuestContract } from "../../abi";
import { getEthersSigner } from "./ethers-adapters";

export const getCompletedQuests = async (
  config: Config,
  chainId: number,
  address: `0x${string}`
): Promise<number[]> => {
  try {
    const ledgerNFTContract = new ethers.Contract(
      process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_QUEST_CONTRACT,
      LedgerQuestContract,
      await getEthersSigner(config, { chainId })
    );
    const tokenIdsOfOwner: number[] = await ledgerNFTContract.walletOfOwner(
      address
    );
    return tokenIdsOfOwner?.map((id) => {
      const categoryId = Math.floor(id / 10 ** 10);
      return Math.floor(id / 10 ** 6) - categoryId * 10000;
    });
  } catch (e) {
    console.log(e);
  }
  return [];
};
