import Modal from "react-modal";
import CloseIcon from "@material-ui/icons/Close";
import { Props } from "../Button";

export interface ModalProps {
  close: () => void;
  title: string;
  message?: string;
  icon?: JSX.Element;
  button?: React.ReactElement<Props>;
  error?: boolean;
  success?: boolean;
}

function StatusModal(props: ModalProps) {
  const {
    close,
    icon,
    title,
    button,
    message,
    error = false,
    success = false,
  } = props;

  const isErrorStyles = () => (error ? "text-red" : "");
  const isSuccessStyles = () => (success ? "text-green" : "");

  return (
    <Modal
      isOpen
      onRequestClose={close}
      className="flex flex-col text-center sm:w-auto w-[90%] bg-black rounded-xl text-white pb-8 sm:px-8 px-4 relative min-w-[384px]"
      //   this inline css is needed to set the overlay of the modal
      style={{
        overlay: {
          zIndex: 99,
          backgroundColor: "rgba(20, 20, 20, 0.9)",
        },
      }}
      data-testid="modal"
      ariaHideApp={false}
    >
      <button
        type="button"
        className="absolute right-4 top-4"
        onClick={close}
        data-testid="close-modal"
      >
        <CloseIcon />
      </button>
      <div className={`py-8 bodyText ${isSuccessStyles()} `}>{title}</div>
      {icon && (
        <div
          className={`flex mx-auto ${isErrorStyles()} ${isSuccessStyles()}`}
          data-testid="icon"
        >
          {icon}
        </div>
      )}
      {message && (
        <p
          className={`mt-6 flex max-w-xs self-center ${isErrorStyles()}`}
          data-testid="message"
        >
          {message}
        </p>
      )}
      {button && <div className="flex mx-auto mt-10">{button}</div>}
    </Modal>
  );
}

export default StatusModal;
