import { TFunction } from "i18next";

export const handleError = (err: Error, t: TFunction): string => {
  console.log(err.message);
  const message = err.message.toLowerCase();
  if (message.includes("resource unavailable")) {
    return t("error.unavailableResource");
  } else if (
    message.includes("switching chain") ||
    message.includes("already pending")
  ) {
    return t("error.chainSwitchError");
  } else if (message.includes("connector not found")) {
    return t("error.connectorNotFound");
  } else if (message.includes("verifying message")) {
    return t("error.messageNotVerified");
  } else if (
    (message.includes("denied") || message.includes("rejected")) &&
    (message.includes("signature") || message.includes("request"))
  ) {
    return t("error.signatureRejected");
  }
  return t("error.globalErrorMessage");
};
