import { createConfig, http } from "wagmi";
import { polygon } from "wagmi/chains";
import type { IronSessionOptions } from "iron-session";
import { injected, walletConnect } from "wagmi/connectors";
import { EnvType } from "../src/types";

const isDev =
  (process.env.NEXT_PUBLIC_CURRENT_ENVIRONMENT as EnvType) === "dev";

export const ironOptions: IronSessionOptions = {
  cookieName: "siwe",
  password: process.env.IRON_SESSION_SECRET,
  cookieOptions: {
    secure: !isDev,
  },
};

// Extend the global Window interface directly in this file
declare global {
  interface Window {
    magicEden?: {
      ethereum?: {
        request: (args: { method: string; params?: any[] }) => Promise<any>;
        on: (event: string, handler: (...args: any[]) => void) => void;
        removeAllListeners: () => void;
        // Add other methods and properties as needed
      };
    };
    magicEdenFailedInjection?: {
      ethereum?: {};
    };
  }
}

export const MAGIC_EDEN = injected({
  target() {
    const provider =
      typeof window !== "undefined" ? window.magicEden?.ethereum : undefined;
    return {
      id: "MagicEden",
      name: "Magic Eden",
      icon: "/icons/MagicEden-Logo.svg",
      provider: provider as any, // We still use `any` here to bypass strict type checking for the provider from WAGMI
    };
  },
});
const METAMASK = injected({
  target() {
    const provider =
      typeof window !== "undefined" ? window.ethereum : undefined;
    return {
      id: "MetaMask",
      name: "MetaMask",
      icon: "/icons/MetaMask-Logo.svg",
      provider: provider as any, // We still use `any` here to bypass strict type checking for the provider from WAGMI
    };
  },
}); // MetaMask, Zerion and other "injected wallets"

const WALLETCONNECT = walletConnect({
  projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
  metadata: {
    name: "Ledger Quest",
    description:
      "Pick a quest based on topic, or the NFT reward you want to win. Next, connect your crypto wallet and check if you qualify for the quest.\n",
    url: "https://quest.ledger.com",
    icons: ["/icons/ledger_logo_mobile.svg"],
  },
  qrModalOptions: {
    themeMode: "dark",
    enableExplorer: false,
    themeVariables: {
      "--wcm-z-index": "9999999",
    },
  },
});

export const config = createConfig({
  // The first chain in this list will be the default network (Should be Polygon)
  ssr: true,
  chains: [polygon],
  multiInjectedProviderDiscovery: false, // We want to limit the list of providers
  connectors: [MAGIC_EDEN, METAMASK, WALLETCONNECT],
  transports: {
    [polygon.id]: http(process.env.NEXT_PUBLIC_TYPEDDATADOMAIN_NETWORK_URI),
  },
});

export default config;
