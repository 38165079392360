import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import router from "next/router";
import { useTranslation, Trans } from "next-i18next";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Connector, useConfig } from "wagmi";
import { useLoginContext } from "../../hooks";
import styles from "./Login.module.css";
import ButtonLogin from "./ButtonLogin";
import LoginTrackingModal from "./LoginTrackingModal";
import { EnvType } from "../../src/types";

interface LoginProps {
  isOpen: boolean;
  redirectToProfile?: boolean;
  close: () => void;
}

function Login({ isOpen, close, redirectToProfile = false }: LoginProps) {
  const {
    login,
    isOnWrongNetwork,
    isSignedIn,
    isConnectError,
    isSigning,
    reset,
  } = useLoginContext();

  const title = "Connect Your Wallet";
  const { t } = useTranslation("common");

  const [chosenConnector, setChosenConnector] = useState<Connector | null>(
    null
  );

  // We extend Connector type here to add "installed" field
  const [connectors, setConnectors] = useState<
    (Connector & { installed: boolean })[]
  >([]);

  // This helper function checks availability of connector
  const isConnectorAvailable = async (
    connector: Connector
  ): Promise<boolean> => {
    const provider = await connector.getProvider();
    if (provider === undefined) {
      return false;
    }
    // MagicEden will replace MetaMask if MM is not installed.
    // @ts-ignore
    if (connector.id === "MetaMask" && provider.isMagicEden) {
      return false;
    }
    return true;
  };

  // Preparing connectors
  const config = useConfig();
  useEffect(() => {
    (async () =>
      setConnectors(
        await Promise.all(
          config.connectors.map(async (item) => ({
            ...item,
            installed: await isConnectorAvailable(item),
          }))
        )
      ))();
  }, [config]);

  const handleLogin = async (connector: Connector) => {
    if (!(await isConnectorAvailable(connector))) {
      if (connector.id === "MagicEden") {
        window.open("https://wallet.magiceden.io/download", "_blank");
      } else if (connector.id === "MetaMask") {
        window.open("https://metamask.io/download/", "_blank");
      }
      return;
    }

    window.dataLayer.push({
      event: "login",
      method: connector.name,
    });

    setChosenConnector(connector);
    login(connector, redirectToProfile);
    close();
  };

  const closeTrackingModal = () => {
    setChosenConnector(null);
    reset();
  };

  const redirectToFaq = () => {
    router.push(`/faq#content5`);
    close();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={close}
        className="flex flex-col sm:w-[450px] w-[90%] bg-black rounded-md absolute max-h-[80vh] text-center text-white pb-[32px] sm:px-[32px] px-[16px]"
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
      >
        <button
          type="button"
          className="absolute right-[16px] top-[16px]"
          onClick={close}
        >
          <CloseIcon />
        </button>
        <div className="py-8 heading-3">{title}</div>

        <div
          className={`flex flex-col gap-2 justify-start items-center overflow-y-auto ${styles.scrollbar}`}
        >
          {connectors.map((item) => (
            <ButtonLogin
              key={item.uid}
              imgUrl={
                item.name === "WalletConnect"
                  ? "/icons/walletconnect_logo.svg"
                  : item.icon
              }
              text={item.name}
              installed={item.installed}
              action={() => handleLogin(item)}
            />
          ))}
        </div>
        <div className="flex items-center mt-4 mb-2 ">
          <InfoOutlined fontSize="small" />
          <p className="ml-2 captionText text-left">{t("disclaimer.title")}</p>
        </div>
        <div className="captionText text-left">
          <Trans
            components={{
              linked: (
                <button
                  type="button"
                  onClick={() => redirectToFaq()}
                  className="underline cursor-pointer"
                />
              ),
            }}
            i18nKey="common:disclaimer.description"
          />
        </div>
      </Modal>
      {chosenConnector !== null && (
        <LoginTrackingModal
          closeModal={closeTrackingModal}
          chosenConnector={chosenConnector}
          isConnectError={isConnectError}
          isSignedIn={isSignedIn}
          isOnWrongNetwork={isOnWrongNetwork}
          isSigning={isSigning}
        />
      )}
    </>
  );
}

export default Login;
