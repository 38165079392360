import { SiweMessage } from "siwe";
import formatServerUrl from "../../helpers/formatServerUrl";

export const apiIsSignedIn = async (address: string): Promise<boolean> => {
  let resp: { address: string };
  try {
    const serverUrl = formatServerUrl("/api/me");
    resp = await (await fetch(serverUrl)).json();
  } catch (err) {
    console.error(err.message);
  }
  return resp.address !== undefined && resp.address === address;
};

export const apiNonce = async (): Promise<string> => {
  const serverUrl = formatServerUrl("/api/nonceWagmi");
  const response = await fetch(serverUrl);
  return response.text();
};

export const apiSignIn = async (
  message: SiweMessage,
  signature: `0x${string}`
) => {
  // Verify signature
  const serverUrl = formatServerUrl("/api/verify");
  const response = await fetch(serverUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ message, signature }),
  });
  if (!response.ok) {
    throw new Error("Error verifying message");
  } else {
    console.log("Authorized");
  }
};

export const apiSignOut = async () => {
  const serverUrl = formatServerUrl("/api/logout");
  await fetch(serverUrl, {
    method: "POST",
    body: "logout",
  });
};
