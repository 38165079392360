// TODO error handling

import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Connector,
  useAccount,
  useConfig,
  useConnect,
  useDisconnect,
  useSignMessage,
  useSwitchChain,
} from "wagmi";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { SiweMessage } from "siwe";
import { apiIsSignedIn, apiNonce, apiSignIn, apiSignOut } from "./api-client";
import { getCompletedQuests } from "./onchain-client";
import { handleError } from "./error-handler";

type OnChainProvider = {
  addCompletedQuest: (arg0: number) => void;
  isConnectError: string | null;
  isLoading: boolean;
  isOnWrongNetwork: boolean;
  isProcessing: boolean;
  isReadyToQuest: boolean;
  isSignedIn: boolean;
  isSigning: boolean;
  login: (connector: Connector, redirectToProfile: boolean) => void;
  logout: () => void;
  reset: () => void;
  userCompletedQuestsAsynchronous: number[];
};

export type LoginContextData = {
  onChainProvider: OnChainProvider;
} | null;
const LoginContext = React.createContext<LoginContextData>(null);

export const useLoginContext = () => {
  const loginContext = useContext(LoginContext);
  if (!loginContext) {
    throw new Error(
      "useLoginContext() can only be used inside of <useLoginContextProvider />, " +
        "please `declare` it at a higher level."
    );
  }
  const { onChainProvider } = loginContext;
  return useMemo(() => ({ ...onChainProvider }), [loginContext]);
};

export const LoginContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isReadyToQuest, setIsReadyToQuest] = useState<boolean>(false);
  const [isOnWrongNetwork, setIsOnWrongNetwork] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false); // Login is started
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);
  const [isSigning, setIsSigning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connectError, setConnectError] = useState<string | null>(null);
  const [userCompletedQuestsAsynchronous, setUserCompletedQuestsAsynchronous] =
    useState<number[]>([]);

  const router = useRouter();
  const { t } = useTranslation("common");

  // region WAGMI Hooks
  const config = useConfig();
  const {
    address,
    chain,
    connector: activeConnector,
    status: connectionStatus,
  } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const { connectAsync } = useConnect({
    mutation: {
      onMutate() {
        setConnectError(null);
      },
      onError(err) {
        setConnectError(handleError(err, t));
      },
    },
  });

  const { switchChainAsync } = useSwitchChain({
    mutation: {
      onMutate() {
        setConnectError(null);
      },
      onError(err) {
        setConnectError(handleError(err, t));
      },
    },
  });

  const { signMessageAsync } = useSignMessage({
    mutation: {
      onMutate() {
        setConnectError(null);
      },
      onError(err) {
        setIsSigning(false);
        setConnectError(handleError(err, t));
      },
    },
  });

  // endregion

  // `loginCheckCompletedQuests` is the last stage of login process
  // We do it in background to get completed quests
  const loginSetCompletedQuests = async (
    chainId: number,
    address: `0x${string}`
  ) => {
    const completedQuests = await getCompletedQuests(config, chainId, address);
    setUserCompletedQuestsAsynchronous(completedQuests);
  };

  // `loginAuthorize` is the third stage of login process
  // It includes signing a message and authorize using an API call
  const loginAuthorize = async (chainId: number, address: `0x${string}`) => {
    if (!(await apiIsSignedIn(address))) {
      setIsSigning(true);
      const nonce = await apiNonce();
      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: "Sign in with Ethereum to the app.",
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce,
      });
      const signature = await signMessageAsync({
        account: address,
        message: message.prepareMessage(),
      });
      await apiSignIn(message, signature);
    }
  };

  // `loginToggleChain` is the second stage of login process
  // Uses (WAGMI) config.chains[0] as a fallback network
  const loginToggleChain = async (currentChainId: number): Promise<number> => {
    if (!config.chains.map((i) => i.id).includes(currentChainId)) {
      await switchChainAsync({ chainId: config.chains[0].id });
      return config.chains[0].id;
    }
    return currentChainId;
  };

  // Call `login` to initiate the process. `retry` is deprecated, use `login` instead
  const login = async (
    connector: Connector,
    shouldRedirectToProfile: boolean
  ) => {
    setIsLoading(true);
    setIsProcessing(true);
    setIsSigning(false);

    try {
      let accountAddress: `0x${string}`;
      let chainId: number;

      // Already connected but trying to reconnect with a different connector
      let forceReconnect: boolean = false;
      if (activeConnector && connector.uid !== activeConnector.uid) {
        forceReconnect = true;
        await disconnectAsync();
      }

      if (connectionStatus !== "connected" || forceReconnect) {
        const connection = await connectAsync({ connector });
        [accountAddress] = connection.accounts;
        chainId = await loginToggleChain(connection.chainId);
      } else {
        accountAddress = address;
        chainId = await loginToggleChain(chain ? chain.id : -1);
      }
      await loginAuthorize(chainId, accountAddress);
      loginSetCompletedQuests(chainId, accountAddress);
    } catch (e) {
      console.log(e);
      return;
    }

    setIsProcessing(false); // Login popup needs this
    setIsReadyToQuest(true);
    setIsSignedIn(true);
    setIsLoading(false);

    if (shouldRedirectToProfile) {
      router.push("/profile/hub");
    }
  };

  const reset = () => {
    setIsProcessing(false);
    setIsSigning(false);
    setIsLoading(false);
  };

  const logout = async () => {
    setIsSignedIn(false);
    setIsReadyToQuest(false);
    await apiSignOut();
    await disconnectAsync();
    if (window?.location.pathname === "/result") {
      window.history.back();
    }
  };

  const addCompletedQuest = (questCollectionId: number) => {
    setUserCompletedQuestsAsynchronous([
      ...userCompletedQuestsAsynchronous,
      questCollectionId,
    ]);
  };

  // For dev purposes
  // useEffect(() => {
  //   apiSignOut();
  // }, []);

  useEffect(() => {
    if (connectionStatus === "connected" && !isProcessing) {
      login(activeConnector, false);
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connectionStatus !== "connected") return;
    if (config.chains.includes(chain)) {
      setIsOnWrongNetwork(false);
    } else {
      setIsOnWrongNetwork(true);
    }
  }, [chain]);

  const onChainProvider = useMemo(
    () => ({
      login,
      reset,
      logout,
      isReadyToQuest,
      isOnWrongNetwork,
      isProcessing,
      isSignedIn,
      isConnectError: connectError,
      isSigning,
      isLoading,
      userCompletedQuestsAsynchronous,
      addCompletedQuest,
    }),
    [
      login,
      reset,
      logout,
      isReadyToQuest,
      isOnWrongNetwork,
      isProcessing,
      connectError,
      isSigning,
      isLoading,
      userCompletedQuestsAsynchronous,
      addCompletedQuest,
    ]
  );
  // @ts-ignore
  return (
    // @ts-ignore
    <LoginContext.Provider value={{ onChainProvider }}>
      {children}
    </LoginContext.Provider>
  );
};
